import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { menuData } from "../data/MenuData"
import { ExternalLinkButton } from "./ExternalLinkButton"
import image from "../assets/images/glammy-logo-normal.png"
import Burger from "./Burger"

const Header = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0)
  const [isHeaderVisible, setHeaderVisibility] = React.useState(true)
  const [isBurgerOpen, setBurgerStatus] = React.useState(false)

  // Hide/Show Header on scroll down/up
  const handleScroll = () => {
    setScrollPosition(document.body.getBoundingClientRect().top)
    setHeaderVisibility(
      document.body.getBoundingClientRect().top >= scrollPosition
    )
    setBurgerStatus(false)
  }

  const handleBurgerClick = () => {
    setBurgerStatus(!isBurgerOpen)
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  return (
    <Nav className={isHeaderVisible ? "active" : "hidden"}>
      <NavLink className="logo" to="/">
        <Logo src={image} />
      </NavLink>
      <Burger click={handleBurgerClick} />
      <NavMenu open={isBurgerOpen}>
        {menuData.map((item, index) => (
          <NavLink activeClassName="nav-active" key={index} to={item.link}>
            {item.title}
          </NavLink>
        ))}
      </NavMenu>
      <NavBtn>
        <ExternalLinkButton primary="true" round="true" target="_blank" href="https://booksy.com/pl-pl/20099_glammy-beauty-mani_paznokcie_8820_krakow">
          Umów się na wizytę
        </ExternalLinkButton>
      </NavBtn>
    </Nav>
  )
}

export default Header

const Nav = styled.nav`
  position: fixed;
  top: 0;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.2s all;
  &.active {
    visibility: visible;
    transform: translateY(0%);
  }
  &.hidden {
    visibility: hidden;
    transform: translateY(-100%);
  }
`
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;

  .nav-active {
    color: #efd3cf;
  }
  .nav-active:before,
  a:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 15px;
    width: 100%;
    height: 3px;
  }
  .nav-active:before {
    background-color: #efd3cf;
  }
  a:not(.nav-active):hover {
    color: #efd3cf;
  }
  a:not(.nav-active):hover:before {
    background-color: #efd3cf;
    animation-name: underline;
    animation-duration: 0.6s;
  }
  @media screen and (max-width: 1078px) {
    position: absolute;
    right: 0;
    top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    background-color: #efd3cf;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    width: 40vw;
    height: 50vh;
    transition: 0.2s all;

    a:before {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    height: 60vh;
    width: 90vw;
  }
`

const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  position: relative;
  transition: 0.2s;
  line-break: strict;
  font-size: 1.8rem;
  font-family: "Gentium Book Basic";
  font-style: italic;

  &.logo:before {
    display: none;
  }
  &.logo:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 1078px) {
    &:not(.logo) {
      box-sizing: border-box;
      color: #777;
      width: 100%;
      border: 2px solid rgba(255, 255, 255, 0.2);
    }
  }

  @keyframes underline {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
`

const Logo = styled.img`
  width: 80px;
  filter: brightness(1.2);
  transform: translateX(50%);
  @media screen and (max-width: 1078px) {
    width: 80px;
  }
`

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 1078px) {
    display: none;
  }
`
