import React from "react"
import styled from "styled-components"

const Burger = props => {
  return (
    <StyledBurger onClick={props.click}>
      <div></div>
      <div></div>
      <div></div>
    </StyledBurger>
  )
}

export default Burger

const StyledBurger = styled.div`
  display: none;
  height: 2rem;
  width: 2rem;

  div {
    height: 0.4rem;
    background: white;
    width: 100%;
  }

  @media screen and (max-width: 1078px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    right: 9px;
    transform: translate(-100%, 75%);
    cursor: pointer;
  }
`
