import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogPost = ({ pageContext: { id }, data: DatoCmsArticle }) => {
  const { title, creationDate, articleImage } = DatoCmsArticle.datoCmsArticle
  const alt = articleImage.alt
  const articleContent =
    DatoCmsArticle.datoCmsArticle.articleContentNode.childMarkdownRemark.html

  return (
    <Layout>
      <BlogPostWrapper>
        <BlogPostContainer>
          <BlogPostImage
            image={getImage(articleImage)}
            alt={alt ? alt : "Blog Post Heading Image"}
          />
          <BlogPostInfo>
            <BlogPostInfoDate>{creationDate}</BlogPostInfoDate>
            <BlogPostInfoAuthor>
              <span>Autor:</span> Paulina Łukaszek
            </BlogPostInfoAuthor>
          </BlogPostInfo>
          <BlogPostMain>
            <BlogPostShare>Share</BlogPostShare>
            <BlogPostContent>
              <h1 className="blog-post-title">{title}</h1>
              <div
                /* html transformed from markdown*/
                dangerouslySetInnerHTML={{
                  __html: articleContent,
                }}
              ></div>
            </BlogPostContent>
          </BlogPostMain>
        </BlogPostContainer>
      </BlogPostWrapper>
    </Layout>
  )
}

export default BlogPost

const BlogPostWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 81px - 5vh);
  display: flex;
  justify-content: center;
  align-items: center;
`

const BlogPostContainer = styled.div`
  width: 80vw;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: space-around;
  padding: 20px;
  font-family: "Gentium Book Basic";
  @media screen and (max-width: 768px) {
    width: 100vw;
    padding: 10px;
  }
`
const BlogPostImage = styled(GatsbyImage)`
  border-radius: 12px;
  width: 100%;
  aspect-ratio: 32/9;
  align-self: center;
  @media screen and (max-width: 768px) {
    aspect-ratio: 16/9;
  }
`
const BlogPostInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`
const BlogPostInfoDate = styled.p`
  font-weight: bold;
  color: #f6b597;
`
const BlogPostInfoAuthor = styled.p`
  text-align: right;
  span {
    font-weight: bold;
    color: #f6b597;
  }
`
const BlogPostMain = styled.div`
  box-shadow: 0px 0px 2px rgba(230, 120, 100, 0.7);
  width: 100%;
  min-height: 30vh;
  display: flex;
`
const BlogPostShare = styled.div`
  color: #f6b597;
  font-weight: bold;
  padding: 5px;
  width: 60px;
  text-align: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
`
const BlogPostContent = styled.div`
  padding: 15px;
  width: 100%;
  word-break: break-word;

  h1.blog-post-title {
    margin: 5px 0;
    font-style: italic;
    font-weight: 700;
    font-size: 3rem;
    color: #f6b597;
    text-shadow: 1px 1px 2px rgba(230, 120, 100, 0.7);
    line-height: 1.3;
    @media screen and (max-width: 768px) {
      font-size: 1.7rem;
    }
  }

  div {
    text-align: left;
    line-height: 1.2;
    @media screen and (max-width: 768px) {
      p {
        font-size: 1rem;
      }
    }
  }
`

export const query = graphql`
  query getArticle($id: String) {
    datoCmsArticle(id: { eq: $id }) {
      title
      creationDate
      articleImage {
        gatsbyImageData(placeholder: BLURRED)
        alt
      }
      articleContentNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
