import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FaFacebook, FaInstagram, FaPhone } from "react-icons/fa"
import styled from "styled-components"

const SocialBar = props => {
  const socialData = useStaticQuery(graphql`
    query SocialBarQuery {
      allDatoCmsSocialbar {
        edges {
          node {
            facebook
            instagram
          }
        }
      }
    }
  `)
  const facebookLink = socialData.allDatoCmsSocialbar.edges[0].node.facebook
  const instagramLink = socialData.allDatoCmsSocialbar.edges[0].node.instagram

  return (
    <SocialBarWrapper className={props.contactClass}>
      <SocialLink href={facebookLink} target="_blank">
        <FaFacebook className="social-icon fb" />
      </SocialLink>
      <SocialLink href={instagramLink} target="_blank">
        <FaInstagram className="social-icon ig" />
      </SocialLink>
      {props.contactClass && (
        <SocialLink href="tel: 666-168-012">
          <FaPhone className="social-icon ph" />
        </SocialLink>
      )}
    </SocialBarWrapper>
  )
}

export default SocialBar

const SocialBarWrapper = styled.div`
  position: fixed;
  bottom: 15px;
  left: 5px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.socialbar-contact {
    position: relative;
    bottom: 0;
    left: 0;
    flex-direction: row;
    z-index: inherit;
    gap: 30px;
  }

  @media screen and (max-width: 992px) {
    &:not(.socialbar-contact) {
      display: none;
    }
  }
`
const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  text-decoration: none;
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  .social-icon {
    font-size: 45px;
    stroke: black;
    fill: white;
    stroke-width: 5px;
    transition: 0.3s all;
    &:hover {
      transform: scale(1.1);
      fill: rgba(230, 120, 100, 1);
    }

    &.fb:hover {
      fill: royalblue;
    }
    &.ig:hover {
      fill: pink;
    }
    &.ph:hover {
      fill: tomato;
    }
  }
`
