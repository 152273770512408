import React from "react"
import styled from "styled-components"

const SectionText = props => {
  return <StyledText className={props.className}>{props.children}</StyledText>
}

export default SectionText

const StyledText = styled.p`
  color: #555;
  font-family: "Gentium Book Basic";
  font-style: italic;
  font-weight: 400;
  font-size: 1.8rem;
  text-align: center;
  text-shadow: 1px 1px 2px #aaa;
  /* text-shadow: 1px 1px 2px rgba(230, 120, 100, 0.5); */
  &.services-text {
    padding: 10px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
  }
`
