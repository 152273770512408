import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 16px;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	margin-top:80px;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


html{
    overflow-y: scroll;
    scroll-behavior: smooth;
	font-size: 16px;
  scroll-padding:10px;
}

h1{
	font-size: 2rem;
}
h2{
	font-size: 1.6rem;
}
h3{
	font-size: 1.4rem;
}
h4{
	font-size: 1.3rem;
}
h5{
	font-size: 1.2rem;
}
h6{
	font-size: 1.1rem;
}

ul {
      list-style: inside;
    }
    ol {
      list-style: arial inside;
    }

    p,
    ul,
    ol {
      font-size: 20px;
    }
    blockquote {
      color: #777;
      font-style: italic;
    }
    strong {
      font-weight: 700;
    }
    em {
      font-style: italic;
    }
`
