export const menuData = [
  {
    title: "Oferta",
    link: "/pricing",
  },
  {
    title: "Aktualności",
    link: "/blog",
  },
  {
    title: "Galeria",
    link: "/gallery",
  },
  {
    title: "Kontakt",
    link: "/contact",
  },
]
