import React from "react"
import styled from "styled-components"
import SectionText from "./SectionText"

const Footer = () => {
  return (
    <FooterWrapper>
      <SectionText>&copy; Glammy Beauty&amp;Mani 2022</SectionText>
    </FooterWrapper>
  )
}

export default Footer

const FooterWrapper = styled.div`
  position: relative;
  bottom: 0;
  height: 5vh;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
`
